<template>
  <div class="col-12 mb-3" >
    <div class="row justify-content-center">
      <div class="col-11 col-md-7 col-xl-6 mb-3 h-100" >
        <CardPlain padding="py-2 px-3" class="mb-3">
          <div class="row h-100 align-items-center" @click="selectDonor(donorOptions.donor.entries().next().value)">
            <div class="col-12" >
              <div class="form-check medium">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <input class="form-check-input" type="radio" name="option" :value="donorOptions.donor.entries().next().value[0]" id="primarydonor" :checked="sadaqahUserDonatingType === 'individual' && donatingID === parseInt(donorOptions.donor.entries().next().value[0].slice(3))">
                  </div>
                  <div class="col p-0">
                    <label class="form-check-label w-100 text-start" for="primarydonor">
                      <div class="row">
                        <div class="col-12 font10 lh-sm">
                          DONOR ID: {{donorOptions.donor.entries().next().value[1].userDetails.donorID}}
                        </div>
                        <div class="col-12 lh-sm">
                          {{donorOptions.donor.entries().next().value[1].userDetails.firstName}} {{donorOptions.donor.entries().next().value[1].userDetails.lastName}}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="col-auto">
                    <Button color="gold" size="small" width="100" btnText="Selected" v-if="sadaqahUserDonatingType === 'individual' && donatingID === parseInt(donorOptions.donor.entries().next().value[0].slice(3))" />
                    <Button color="green" size="small" width="100" btnText="Select" v-else />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardPlain>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CardPlain: defineAsyncComponent(() => import('@/components/CardPlain.vue'))
  },
  name: 'Daily Sadaqah Select Donor',
  computed: {
    ...mapGetters([
      'user', 'userDonors', 'donateAs'
    ]),
    donorOptions () {
      const arr = this.userDonors
      const result = {
        donor: {},
        organisations: {},
        groups: {}
      }
      const donor = new Map()
      const organisations = new Map()
      const groups = new Map()
      arr.forEach(el => {
        if (!el.organisationDonor) {
          if (this.donateAs && el.user.userID !== this.donateAs.userID) {
            const item = donor.get('don' + el.user.userID) || { id: 'don' + el.user.userID, userDetails: el.user, count: 0 }
            item.count++
            donor.set(item.id, item)
          }
        } else {
          if (!el.organisation.archive) {
            const item = organisations.get('org' + el.organisation.organisationID) || { id: 'org' + el.organisation.organisationID, userDetails: el.organisation, count: 0 }
            item.count++
            organisations.set(item.id, item)
          }
        }
      })
      result.donor = donor
      result.organisations = organisations
      result.groups = groups
      return result
    }
  },
  methods: {
    ...mapActions(['setDonateAs']),
    selectDonor (val) {
      const type = val[0].slice(0, 3)
      this.donatingID = parseInt(val[0].slice(3))
      this.setDonateAs(val[1].userDetails)
      if (type === 'org') {
        this.donatingType = 'organisation'
      } else if (type === 'gro') {
        this.donatingType = 'group'
      } else {
        this.donatingType = 'user'
      }
    }
  }
}
</script>
